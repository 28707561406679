@font-face {
  font-family: "Proxima Nova";
  src: url("../../public/fonts/ProximaNova-Regular.ttf");
}

/* resetting react-dropdown-tree-select styles */
.react-dropdown-tree-select .dropdown {
  position: relative;
  display: flex;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  top: 44px;
  width: 380px;
}

.tag-item .search {
  background-color: transparent;
}
